<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
      <el-breadcrumb-item :to="{ path: '/home' }" >首页</el-breadcrumb-item>
      <el-breadcrumb-item >集群资源使用状况</el-breadcrumb-item>
    </el-breadcrumb>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="节点1" name="first">
        <node1-monitor></node1-monitor>
      </el-tab-pane>
      <el-tab-pane label="节点2" name="second">
        <node2-monitor></node2-monitor>
      </el-tab-pane>
      <el-tab-pane label="节点3" name="third">
        <node3-monitor></node3-monitor>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Node1Monitor from "./node1Monitor";
import Node2Monitor from "./node2Monitor";
import Node3Monitor from "./node3Monitor";
export default {
  name: "operationCenter",
  components: {
    Node1Monitor,
    Node2Monitor,
    Node3Monitor
  },
  data() {
    return {
      activeName:'first',
    }
  },
  methods: {
    handleClick(tab, event) {
      this.$forceUpdate();
    },
  },
}
</script>

<style scoped>
</style>
