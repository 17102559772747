<template>
  <div>
    <iframe id="iframe1"
            :src="url"
            frameborder="0"
            scrolling="yes"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "node1Monitor",
  data() {
    return {
      url: `http://${this.$store.state.monitor.lab3}:${this.$store.state.monitor.port}/d/Bkl9bBYX/zhongmeilab3?orgId=1&var-job=node+exporter&var-node=lab1%3A8100&var-maxmount=%2F&var-nic=&theme=light&kiosk`,
    }
  },
  mounted() {
    function changeMobsfIframe(){
      const iframe = document.getElementById('iframe1');
      const deviceWidth = document.body.clientWidth;
      const deviceHeight = document.body.clientHeight;
      iframe.style.width = (Number(deviceWidth)-120) + 'px'; //数字是页面布局宽度差值
      iframe.style.height = (Number(deviceHeight)-160) + 'px'; //数字是页面布局高度差
    }
    changeMobsfIframe()
    window.onresize = function(){
      changeMobsfIframe()
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
